import { Flex } from "antd";
import dayjs from "dayjs";
import React from "react";

const ShowMore = ({ meetingSubject, meetingStartTime, meeting, showModal }) => {
  let meetingStart = dayjs(meetingStartTime).format("dddd, MMMM DD - hh:mm A");
  return (
    <Flex
      align="center"
      gap={"2.5rem"}
      onClick={() => showModal(meeting)}
      style={{ cursor: "pointer" }}
    >
      <div
        style={{
          width: "1rem",
          height: "1rem",
          backgroundColor: `${
            meetingStartTime.toISOString().split("T")[0] <
            dayjs().format("YYYY-MM-DD")
              ? "#C7C8CC"
              : meeting.createdBy !== meeting.empCode
              ? "#ADD8E6"
              : "#90EE90"
          }`,
          borderRadius: "0.2rem",
        }}
      ></div>
      <Flex style={{ flexDirection: "column" }}>
        <p style={{ fontSize: "1.5rem", fontWeight: "600", margin: "0" }}>
          {meetingSubject}
        </p>
        <p style={{ margin: "0" }}>{meetingStart}</p>
      </Flex>
    </Flex>
  );
};

export default ShowMore;
