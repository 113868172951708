import React from 'react';
import moment from "moment";
import dayjs from 'dayjs';

export const CustomToolbar = (toolbar) => {
  const currentView=toolbar.view;
  const toolbarYear=dayjs(toolbar.date).year()
  const splitDates = toolbar.label.split("–").map(date => date.trim());
  const currentYear=dayjs().year()
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const label = () => {
   
    const date = toolbar.label;
    return (
      <span>
       {date}{" "}{
        currentView==='week' && (toolbarYear)
       }
      </span>
    );
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>Previous</button>
        <button type="button" onClick={goToCurrent}>Today</button>
        <button type="button" onClick={goToNext}>Next</button>
      </span>
      <span className="rbc-toolbar-label">{label()}</span>
    </div>
  );
};

