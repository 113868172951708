import React, { useEffect, useState } from "react";
import { Button, Checkbox, Flex, Modal, Switch, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./styles.css";
import TableComponent from "../other/TableComponent";
import MeetingRoomPopup from "./MeetingRoomPopup";
import { clientsApi, meetingRoomApis } from "../../api";
import DeletePopup from "./DeleteMeetingPopup";
import DeleteMeetingRoomPopup from "./DeleteMeetingRoomPopup";

const data = [
  {
    key: 1,
    meetingRoomName: "Room 1",
    place: "Banglore",
    seatingCapacity: "20",
    floor: "1",
    isProjector: false,
    isDisplayScreen: true,
    isApproved: true,
    meetingRoomLink: "Person 1",
  },
  {
    key: 2,
    meetingRoomName: "Room 2",
    client: "Client 2",
    place: "Jodhpur",
    seatingCapacity: "10",
    floor: "1",
    isProjector: true,
    isDisplayScreen: false,
    meetingRoomLink: "Person 2",
    lastUpdatedOn: "30/04/22",
  },
];

const Sow = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [allClientDetails, setAllClientDetails] = useState({
    allRooms:[],
    inActiveRooms:[]
  });
  const [cancelled, setCancelled] = useState(false);
  const [loading,setLoading]=useState(false);
  const [showInactiveRooms,setShowInactiveRooms]=useState(false)
  

  const handleActiveChange=(checked,mrId,name)=>{
    showDeleteModal(mrId,checked,name)
  }

  const handleMaintenanceChange=(checked,mrId,name)=>{
    showDeleteModal(mrId,checked,name)
  }

  const showModal = (key) => {
    setSelectedKey(key);
    setIsModalOpen(true);
  };
  const showDeleteModal = (key,checked,name) => {
    setSelectedKey({key,[name]:checked});
    setIsDeleteModalOpen(true);
  };
  const handlePopupCancel = (func) => {
    setSelectedKey(null);
    setCancelled(true);
    setIsModalOpen(false);
  };
  const handleDeletePopupCancel = () => {
    setSelectedKey(null);
    setIsDeleteModalOpen(false);
  };

  const getTitle = () => {
    return selectedKey ? selectedKey : "Add Meeting Room";
  };

  const handleInactiveRooms=(e)=>{
      setShowInactiveRooms(e.target.checked)
  }

  useEffect(() => {
   if(!isModalOpen && !isDeleteModalOpen){
    ;(async()=>{
      setLoading(true)
      try{
      const resp=await meetingRoomApis
      .allMeetingRoomData()
      setAllClientDetails((prev)=>{
        const inActiveRooms=resp?.data.filter((room)=>room.isActive==false);
        return {inActiveRooms,allRooms:resp?.data}
      });
        console.log("resp?.data", resp?.data);
    }catch(err){
      console.error("Error fetching data:", err);
    }
    setLoading(false)
     })()
   }
  }, [isModalOpen,isDeleteModalOpen]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 130,
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      width: 110,
    },
    {
      title: "Seating Capacity",
      dataIndex: "seatingCapacity",
      width: 125,
      key: "seatingCapacity",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
      width: 170,
    },
    {
      title: "Projector Availability",
      dataIndex: "isProjector",
      key: "isProjector",
      width: 200,
      render: (_, { isProjector }) =>
        isProjector ? (
          <Tag color="#a0d911">Yes</Tag>
        ) : (
          <Tag color="#f5222d">No</Tag>
        ),
    },
    {
      title: "Display Screen Availability",
      dataIndex: "isDisplayScreen",
      key: "isDisplayScreen",
      width: 150,
      render: (_, { isDisplayScreen }) =>
        isDisplayScreen ? (
          <Tag color="#a0d911">Yes</Tag>
        ) : (
          <Tag color="#f5222d">No</Tag>
        ),
    },
    {
      title: "Currently Active",
      dataIndex: "isActive",
      key: "isActive",
      width: 140,
      fixed: "right",
      render: (_, record) => (
        <>
          <Switch
            checked={record.isActive}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked)=>handleActiveChange(checked,record.mrId,'isActivating')}
          />
            <Modal
            // title={getTitle()}
            visible={isDeleteModalOpen && selectedKey.key === record?.mrId}
            onCancel={handleDeletePopupCancel}
            footer={null}
          >
            <DeleteMeetingRoomPopup
              hide={setIsDeleteModalOpen}
              buttonText={"Delete"}
              clientId={record.mrId}
              meetingRoomData={record}
              popupData={selectedKey}
            />
          </Modal>
        </>
      ),
    },
    {
      title: "Currently Under Maintenance",
      dataIndex: "isUnderMaintenance",
      key: "isUnderMaintenance",
      width: 140,
      fixed: "right",
      render: (_, record) => (
        <>
          <Switch
            checked={record.isUnderMaintenance}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked)=>handleMaintenanceChange(checked,record.mrId,'isUnderMaintenance')}
          />
            <Modal
            // title={getTitle()}
            visible={isDeleteModalOpen && selectedKey.key === record?.mrId}
            onCancel={handleDeletePopupCancel}
            footer={null}
          >
            <DeleteMeetingRoomPopup
              hide={setIsDeleteModalOpen}
              buttonText={"Delete"}
              clientId={record.mrId}
              meetingRoomData={record}
              popupData={selectedKey}
            />
          </Modal>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 140,
      fixed: "right",
      render: (_, record) => (
        <>
          <Button onClick={() => showModal(record.mrId)} type="link">
            <EditOutlined />
          </Button>{" "}
          <Modal
            title={'Edit Meeting Room'}
            visible={isModalOpen && selectedKey === record?.mrId}
            onCancel={handlePopupCancel}
            footer={null}
            style={{marginTop:'-5%'}}

          >
            <MeetingRoomPopup
              hide={setIsModalOpen}
              buttonText={"Update"}
              meetingRoomData={record}
              isEditMode = {true}
            />
          </Modal>
        
        </>
      ),
    },
  ];

  return (
    <>
      <Flex justify="space-between">
        <h2>Meeting Rooms</h2>
        <Button onClick={() => showModal(null)} type="primary">
          Add Meeting Room
        </Button>{" "}
        <Modal
          title={getTitle()}
          visible={isModalOpen && !selectedKey}
          onCancel={handlePopupCancel}
          footer={null}
          style={{marginTop:'-5%'}}
        >
          <MeetingRoomPopup
            hide={setIsModalOpen}
            buttonText={"Save"}
            setCancelled={setCancelled}
            cancelled={cancelled}
            isEditMode = {false}
          />
        </Modal>
      </Flex>
      <Checkbox style={{marginBottom:"1.5rem"}} onChange={handleInactiveRooms}>Inactive meeting rooms</Checkbox>
      <TableComponent
        data={showInactiveRooms ? allClientDetails.inActiveRooms:allClientDetails.allRooms}
        columns={columns}
        scrollX={1400}
        loading={loading}
      />
    </>
  );
};

export default Sow;
