import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Modal, Spin } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { GiEntryDoor } from "react-icons/gi";
import axios from "axios";
import dayjs from "dayjs";
import "./UpNextMeetings.css";
import { employeeId, getToken } from "../../userRole";
import { roomBookingApi } from "../../api";

const { Text, Title } = Typography;

function MeetingCard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    ;(async () => {
      const todayDate = dayjs().format("YYYY-MM-DD");
      const currentTime = dayjs().format("HH:mm:ss");
      const params = {
        date: todayDate,
        time: currentTime,
        employeeNo: employeeId,
      };
      setLoading(true);
      try {
        const resp = await roomBookingApi.getUpcomingMeetings(params);
        console.log("upcoming meetings data", resp?.data);
        const upcomingMeetings = resp?.data.filter((meeting) => {
          const meetingDateTime = dayjs(`${meeting.date} ${meeting.endTime}`);
          return meetingDateTime.isAfter(dayjs());
        });

        setMeetings(upcomingMeetings);
      } catch (err) {
        console.error("Error fetching upcoming meetings data:", err);
      }
      setLoading(false);
    })();
  }, []);

  const showDetails = (meeting) => {
    setCurrentMeeting(meeting);
    setIsModalVisible(true);
  };

  const hideDetails = () => {
    setIsModalVisible(false);
    setCurrentMeeting(null);
  };

  const stripHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const formatMeetingDate = (meetingDate) => {
    const today = new Date();
    const meetingDay = new Date(meetingDate);

    today.setHours(0, 0, 0, 0);
    meetingDay.setHours(0, 0, 0, 0);

    const dayDifference = Math.floor(
      (meetingDay - today) / (1000 * 60 * 60 * 24)
    );

    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formatDateWithOrdinal = (date) => {
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      return `${day}${getOrdinalSuffix(day)} ${month}`;
    };

    if (dayDifference === 0) {
      return "Today";
    } else if (dayDifference === 1) {
      return "Tomorrow";
    } else if (dayDifference > 1 && dayDifference <= 6 - today.getDay()) {
      return meetingDay.toLocaleString("default", { weekday: "long" });
    } else {
      return formatDateWithOrdinal(meetingDay);
    }
  };

  const formatInvitees = (invitees) => {
    const maxInviteesToShow = 2;
    const inviteesCount = invitees.length;

    if (inviteesCount <= maxInviteesToShow) {
      return invitees.join(", ");
    } else {
      const shownInvitees = invitees.slice(0, maxInviteesToShow).join(", ");
      const remainingInviteesCount = inviteesCount - maxInviteesToShow;

      return (
        <>
          {shownInvitees}, +{remainingInviteesCount} more...
        </>
      );
    }
  };

  return (
    <>
      <div className="meeting-cards-grid-container">
        {meetings.length > 0 && (
          <div className="meeting-list-heading">
            <Title level={3}>Up Next :</Title>
          </div>
        )}

        <div className="meeting-cards-grid">
          {loading ? (
            <div className="loading-spinner">
              <Spin size="large" />
              <span style={{ paddingLeft: "10px" }}>Fetching...</span>
            </div>
          ) : meetings.length > 0 ? (
            meetings.map((meeting, index) => (
              <Card
                bordered={true}
                key={index}
                className="meeting-card"
                bodyStyle={{ padding: "8px 16px" }}
              >
                <div className="meeting-header">
                  <div className="divider"></div>
                  <div className="header-content">
                    <Title level={5} className="meeting-description">
                      {meeting.subject}
                    </Title>
                    <Text type="secondary">
                      <span style={{ marginRight: "10px" }}>
                        {formatMeetingDate(meeting.date)}{" "}
                      </span>
                      {new Date(
                        `1970-01-01T${meeting.startTime}`
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -{" "}
                      {new Date(
                        `1970-01-01T${meeting.endTime}`
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Text>
                  </div>
                </div>
                <div className="location-room-info">
                  <GiEntryDoor
                    style={{
                      marginRight: "8px",
                      verticalAlign: "-3px",
                      fontSize: "22px",
                    }}
                  />
                  <Text>{meeting.name}</Text>
                </div>

                <div className="meeting-info">
                  <Text strong>Owner: </Text>
                  <Text>{meeting.meeting_creator}</Text>
                </div>
                <div className="meeting-info">
                  <Text strong>Invitees: </Text>
                  <Text>{formatInvitees(meeting.other_invitees)}</Text>
                </div>
                <div className="details-button">
                  <Button onClick={() => showDetails(meeting)}>
                    View Details
                  </Button>
                </div>
              </Card>
            ))
          ) : (
            <div className="no-meetings-message">
              {/* <DotLottieReact
                src="https://lottie.host/bb8f5c73-8430-477a-9e47-518e1ddbb7c6/QC2q8U1Gkq.lottie"
                loop
                autoplay
                style={{ width: "300px", marginBottom: "20px" }}
              /> */}
              <h2>Currently, there are no scheduled meetings.</h2>
            </div>
          )}
        </div>
      </div>

      {currentMeeting && (
        <Modal
          title={<span style={{ fontSize: "20px" }}>Meeting Details</span>}
          visible={isModalVisible}
          onOk={hideDetails}
          onCancel={hideDetails}
          footer={null}
        >
          <div className="modal-divider" />
          <div className="modal-content">
            <div className="modal-row">
              <strong>Subject :</strong>
              <span>{currentMeeting.subject}</span>
            </div>
            <div className="modal-row">
              <strong>Agenda :</strong>
              <span>{stripHtmlTags(currentMeeting.agenda)}</span>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default MeetingCard;
