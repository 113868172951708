import { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { meetingRoomApis, roomBookingApi } from "../../api";
import { Flex, Modal, Spin } from "antd";
import CalendarPopup from "./CalendarPopup";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { employeeId } from "../../userRole";
import { CustomToolbar } from "./CustomToolbar";
import "./WeeklyCalendar.css";
import { Box } from "@ant-design/plots";
import ShowMore from "./ShowMore";

const localizer = momentLocalizer(moment);
const EventWithTime = ({ event }) => {
  // Format the start and end times
  const startTime = moment(event.start).format("hh:mm A");

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>{startTime}</div>
      <div
        style={{
          "white-space": "nowrap",
          overflow: "hidden",
          "textOverflow": "ellipsis",
          maxWidth: "10ch",
        }}
      >
        {event.title}
      </div>
    </div>
  );
};

const Cr = () => {
  const [monthDates, setMonthDates] = useState(() => {
    const startOfMonth = dayjs().startOf("month");
    const endOfMonth = dayjs().endOf("month");

    return {
      monthStart: startOfMonth.toISOString(),
      monthEnd: endOfMonth.toISOString(),
    };
  });

  const navigate = useNavigate();

  const [myEvents, setEvents] = useState([]);
  const [moreModal, setMoreModal] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendarPopupData, setCalendarPopupData] = useState(null);
  const [meetingRooms, setMeetingRooms] = useState([]);

  useEffect(() => {
    meetingRoomApis.meetingRoomAllData().then((res) => {
      const meetingRooms = res?.data?.map((mRoom) => {
        return {
          value: mRoom.mrId,
          label: mRoom.name,
          place: mRoom.place,
        };
      });
      setMeetingRooms([...meetingRooms]);
    });
  }, []);

  useEffect(() => {
    roomBookingApi
      .getMeetingListData({
        empCode: employeeId,
        startDate: monthDates.monthStart,
        endDate: monthDates.monthEnd,
      })
      .then((res) => {
        console.log("printing meeting list data response ", res);
        const meetingsData = res.data
          .filter((meeting) => {
            return meetingRooms.some((room) => room.value === meeting.mrId);
          })
          .map((meeting) => {
            return {
              start: moment(
                `${meeting.date.split("T")[0]}T${meeting.startTime}`
              ).toDate(),
              end: moment(
                `${meeting.date.split("T")[0]}T${meeting.endTime}`
              ).toDate(),
              title: meeting.subject,
              rtId: meeting.rtId,
              createdBy: meeting.createdBy,
              empCode: meeting.empCode,
              date: meeting.date,
              mrId: meeting.mrId,
              agenda: meeting.agenda,
              meetingRoomName: meeting.name,
              rbId: meeting.rbId,
              meetingRoomLink: meeting.meetingRoomLink,
            };
          });

        const filteredMeetingsData = meetingsData.filter((meeting) => {
          return (
            meeting.start >= new Date(monthDates.monthStart) &&
            meeting.start <= new Date(monthDates.monthEnd)
          );
        });

        setEvents([...filteredMeetingsData]);
      })
      .catch((err) => {
        console.log("error in getting meeting lists api", err);
        setEvents([]);
      });
  }, [monthDates.monthStart, monthDates.monthEnd, isModalOpen, meetingRooms]);


  const showModal = (key) => {
    setCalendarPopupData(key);
    setIsModalOpen(true);
  };
  const handlePopupCancel = () => {
    setCalendarPopupData(null);

    setIsModalOpen(false);
  };

  const onNavigate = (date) => {
    const startOfMonth = dayjs(date).startOf("month").toISOString();
    const endOfMonth = dayjs(date).endOf("month").toISOString();

    setMonthDates({
      monthStart: startOfMonth,
      monthEnd: endOfMonth,
    });
  };

  const handleSelectEvent = (event) => {
    const selectedDate = event.date.split("T")[0];
    const currentDate = dayjs().format("YYYY-MM-DD");
    showModal(event);
  };

  const handleSlot = (slotInfo) => {
    const selectedDate = dayjs(slotInfo.start).format("YYYY-MM-DD");
    const currentDate = dayjs().format("YYYY-MM-DD");

    if (selectedDate >= currentDate) {
      navigate("/meeting-room-booking", { state: { selectedDate } });
    }
  };

  const eventPropGetter = useCallback(
    (meet, start, end, isSelected) => ({
      ...(start.toISOString().split("T")[0] < dayjs().format("YYYY-MM-DD") && {
        style: {
          backgroundColor: "#C7C8CC",
          color: "#000000",
        },
      }),
      ...(meet.createdBy === meet.empCode &&
        start.toISOString().split("T")[0] < dayjs().format("YYYY-MM-DD") && {
          style: {
            backgroundColor: "#C7C8CC",
            color: "#000000",
          },
        }),
      ...(meet.createdBy === meet.empCode &&
        start.toISOString().split("T")[0] >= dayjs().format("YYYY-MM-DD") && {
          style: {
            backgroundColor: "#90EE90",
            color: "#000000",
          },
        }),
      ...(meet.createdBy !== meet.empCode &&
        start.toISOString().split("T")[0] >= dayjs().format("YYYY-MM-DD") && {
          style: {
            backgroundColor: "#ADD8E6",
            color: "#000000",
          },
        }),
    }),
    []
  );

  return (
    <div className="month-view-container">
       
        <Calendar
          localizer={localizer}
          events={myEvents}
          eventPropGetter={eventPropGetter}
          views={{
            month: true
          }}
          onSelectSlot={handleSlot}
          onNavigate={onNavigate}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={(e) => handleSelectEvent(e)}
          selectable
          style={{ height: 550 }}
          popup
          // messages={{
          //   showMore: (cnt, remEvents, events) => {
          //     return (
          //       <div
          //         className="custom-show-more"
          //         onClick={(e) => {
          //           setMoreModal(events);
          //         }}
          //       >
          //         +{cnt} more..
          //       </div>
          //     );
          //   },
          // }}
          components={{
            toolbar: CustomToolbar,
            event: EventWithTime,
          }}
        />
       
      
      <Modal
        open={isModalOpen && calendarPopupData != null}
        onCancel={handlePopupCancel}
        footer={null}
        style={{ width: "100px", position: "fixed", top: "0px", left: "35%" }}
      >
        <CalendarPopup
          event={calendarPopupData}
          handlePopupCancel={handlePopupCancel}
        />
      </Modal>
      <Modal
        open={moreModal}
        onCancel={() => setMoreModal(null)}
        footer={null}
        style={{ width: "100px", position: "fixed", top: "10%", left: "55%" }}
      >
        <Flex style={{ flexDirection: "column", gap: "0.8rem" }}>
          {moreModal?.map((meeting) => (
            <ShowMore
              showModal={showModal}
              meeting={meeting}
              meetingStartTime={meeting.start}
              meetingSubject={meeting.title}
            />
          ))}
        </Flex>
      </Modal>
    </div>
  );
};

export default Cr;
