// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-header {
    font-size: 14px;
    height: 36px;
    font-weight: bold;
}

.rbc-header th {
    display: block;
}

.rbc-allday-cell {
    display: none;
}

.rbc-selected-cell,
.rbc-timeslot-group {
  background-color: transparent !important;
}

.week-view-container .rbc-event{
    width: 110% !important;
}

.rbc-event-label{
    font-size: 10px;
    font-weight: 600;
}

.month-view-container .rbc-event{
    width: 100% !important;
}

.rbc-today{
    background-color: #D4F6FF;
}

.month-view-container .rbc-today{
    border: 1px solid #000 !important
}

.rbc-timeslot-group {
    min-height: 52.40px;
}

.week-view-container .rbc-timeslot-group:nth-child(n+1):nth-child(-n+9){
    
  }

.week-view-container .rbc-timeslot-group:nth-child(n+21):nth-child(-n+24) {

  }
  `, "",{"version":3,"sources":["webpack://./src/components/projectPages/WeeklyCalendar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;;EAEE,wCAAwC;AAC1C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;AACJ;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;EAEE;;AAEF;;EAEE","sourcesContent":[".rbc-header {\n    font-size: 14px;\n    height: 36px;\n    font-weight: bold;\n}\n\n.rbc-header th {\n    display: block;\n}\n\n.rbc-allday-cell {\n    display: none;\n}\n\n.rbc-selected-cell,\n.rbc-timeslot-group {\n  background-color: transparent !important;\n}\n\n.week-view-container .rbc-event{\n    width: 110% !important;\n}\n\n.rbc-event-label{\n    font-size: 10px;\n    font-weight: 600;\n}\n\n.month-view-container .rbc-event{\n    width: 100% !important;\n}\n\n.rbc-today{\n    background-color: #D4F6FF;\n}\n\n.month-view-container .rbc-today{\n    border: 1px solid #000 !important\n}\n\n.rbc-timeslot-group {\n    min-height: 52.40px;\n}\n\n.week-view-container .rbc-timeslot-group:nth-child(n+1):nth-child(-n+9){\n    \n  }\n\n.week-view-container .rbc-timeslot-group:nth-child(n+21):nth-child(-n+24) {\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
