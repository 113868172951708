export const _31DaysMonths = [1, 3, 5, 7, 8, 10, 12];
export const _sortingByNumber = ["seatingCapacity", "floor"];
export const _daysOfWeek = [
  {
    value: 0,
    label: "Sunday",
  },
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
];
export const _dayOfWeek={
    "Sunday":0,
    "Monday":1,
    "Tuesday":2,
    "Wednesday":3,
    "Thursday":4,
    "Friday":5,
    "Saturday":6
}
export const _concurrencyOptions=[
  {
    value:1,
    label:"Once"
  },
  {
    value:3,
    label:"Weekly"
  },
  {
    value:5,
    label:"Every Weekday (Monday To Friday)"
  }
]
// export const emp_code = "SPAN-BLR-2027";
